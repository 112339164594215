<template>
  <div>
    <el-card>
      <template #header>
        <div class="card-header" style="text-align: left">
          <el-col :span="4" class="hidden-md-and-down">
            <span class="card-title">1.选择产品信息</span>
          </el-col>
        </div>
      </template>
      <div class="content">
        <div class="sel-block" v-if="ismerchant != 1">
            <label class="sellabel">选择商户</label>
            <el-select v-model="planinfo.merchantid" clearable filterable size="small" @change="ChangeMerchant">
                <el-option v-for="merchant in merchants" :key="merchant" :value="merchant.ID" :label="merchant.Merchant"></el-option>
            </el-select>
        </div>
        <div class="sel-block">
            <label class="sellabel">选择品类</label>
            <el-cascader
                :options="categorys"
                v-model="planinfo.category"
                placeholder="请选择"
                clearable
                :show-all-levels="false"
                :props="props"
                @change="HandleCateChange"
                size="small"
            ></el-cascader>
        </div>
        <div class="sel-block">
            <label class="sellabel">选择品牌</label>
            <el-select
                v-model="planinfo.brand"
                clearable
                placeholder="请选择"
                filterable
                @visible-change="GetModel"
                :disabled="planinfo.category?false:true"
                @clear="ClearBrand"
                size="small"
            >
                <el-option
                v-for="brand in brands"
                :key="brand.ID"
                :label="brand.Brand"
                :value="brand.ID"
                >
                </el-option>
            </el-select>
        </div>
        <div class="sel-block">
            <label class="sellabel">选择型号</label>
            <el-select
                v-model="planinfo.model"
                placeholder="请选择"
                filterable
                clearable
                @visible-change="GetPdt"
                :disabled="planinfo.brand?false:true"
                @clear="ClearModel"
                size="small"
                >
                <el-option
                v-for="model in models"
                :key="model.ID"
                :label="model.Model"
                :value="model.ID"
                ></el-option>
            </el-select>
        </div>
        <div class="sel-block">
            <label class="sellabel">选择产品</label>
            <el-select
                v-model="planinfo.pdt"
                size="small"
                placeholder="请选择"
                filterable
                :disabled="planinfo.model?false:true"
                @change="NextStep"
                @visible-change="RefleshPdt"
            >
                <el-option
                v-for="product in products"
                :key="product.ID"
                :label="product.PdtName"
                :value="product.ID"
                ></el-option>
            </el-select>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top:15px;" v-if="next">
        <template #header>
            <div class="card-header" style="text-align: left">
            <el-col :span="4" class="hidden-md-and-down">
                <span class="card-title">2.套餐列表</span>
            </el-col>
            </div>
        </template>
        <div class="list">
            <el-button @click="AddPlan" size="small">添加套餐</el-button>
            <el-button @click="BatchAdd" size="small">批量添加</el-button>
            <el-table :data="listdata" size="small" style="margin-top:30px;" v-if="listdata.length > 0">
                <el-table-column type="index" width="80" label="序号"></el-table-column>
                <el-table-column label="参数" prop="Params" min-width="250px"></el-table-column>
                <el-table-column label="标题" min-width="300px">
                    <template #default='scope'>
                        <el-input v-model="scope.row.PlanTitle" size="small"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="库存" width="100">
                    <template #default='scope'>
                        <el-input v-model="scope.row.Stock" size="small"></el-input>
                    </template>
                </el-table-column>
                <el-table-column label="租赁方式" width="150">
                    <template #default='scope'>
                        <span v-if="scope.row.Plantype == 1">租赁</span>
                        <span v-if="scope.row.Plantype == 2">到期赠送</span>
                        <span v-if="scope.row.Plantype == 3">售卖</span>
                        <span v-if="scope.row.Plantype == 4">定制租赁</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="400px">
                    <template #default='scope'>
                        <el-button size="small" type="text" style="margin-right:20px;" @click="EditPlan(scope.row.ID)" :disabled="scope.row.Status == 1?true:false">编辑</el-button>
                        <el-button size="small" type="text" style="margin-right:20px;" @click="DelPlan(scope.row.ID)" :disabled="scope.row.Status == 1?true:false">删除</el-button>
                        <el-button size="small" type="text" style="margin-right:20px;" disabled>上移</el-button>
                        <el-button size="small" type="text" style="margin-right:20px;" disabled>下移</el-button>
                        <el-button size="small" type="text" style="margin-right:20px;" @click="Enable(scope.row.ID)" v-if="scope.row.Status == 0">上架</el-button>
                        <el-button size="small" type="text" style="margin-right:20px;" @click="Disable(scope.row.ID)" v-if="scope.row.Status == 1">下架</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="bottom" v-if="listdata.length > 0">
                <el-button size="small" @click="BatchSave">保存</el-button>
                <el-button size="small" @click="Back">返回</el-button>
            </div>
        </div>
    </el-card>
    <el-dialog v-model="addplan" :title="dialogtitle" center :width="dialogwidth" @close="GetPlan">
        <div style="margin-bottom:25px;border-bottom:1px solid #dcdfe6"></div>
        
        <el-form :model="planinfo" ref="planinfo" label-width="120px" :inline="true" :rules="rules">
            <el-form-item label="选择参数" v-if="stepone">
                <el-select v-model="planinfo.params" multiple filterable clearable placeholder="请选择" @change="SetParam" style="width:100%">
                    <el-option v-for="param in selparam"
                                :key="param.ID"
                                :label="param.Param"
                                :value="param.ID">
                    </el-option>
                </el-select>
            </el-form-item>
            <div v-if="steptwo">
            <el-form-item v-for="param in params" :key="param" :label="param.param_name" :prop="param.param_id">
                <el-select v-model="planinfo[param.param_id]" :multiple="multiple" placeholder="请选择" style="width:300px;" @remove-tag="ReSetTable" filterable>
                    <el-option v-for="val in param.param_value" :key="val" :label="val" :value="val" ></el-option>
                </el-select>
            </el-form-item>
            </div>
            <div v-if="stepthree">
                <el-form-item label="选择租赁方式">
                    <el-select v-model="planinfo.plantype" placeholder="请选择" filterable @change="ResetStart" >
                        <el-option label="租赁" value="1"></el-option>
                        <el-option label="到期赠送" value="2"></el-option>
                        <el-option label="售卖" value="3"></el-option>
                        <el-option label="定制租赁" value="4"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择租期" v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4" >
                        <el-radio-group v-model="planinfo.renttype" @change="ResetType" >
                            <el-radio-button :label="1" v-if="planinfo.plantype == 1 || planinfo.plantype == 4">按天(平均价)</el-radio-button>
                            <el-radio-button :label="2" v-if="planinfo.plantype == 1 || planinfo.plantype == 4">按天(阶梯价)</el-radio-button>
                            <el-radio-button :label="3">按月(平均价)</el-radio-button>
                            <el-radio-button :label="4">按月(阶梯价)</el-radio-button>
                        </el-radio-group>
                </el-form-item>
                <el-form-item label="选择最短租期" v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                        <el-select v-model="planinfo.rankstart" placeholder="请选择" @change="CheckTable">
                            <el-option label="1天" value="1" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="3天" value="2" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="7天" value="3" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="15天" value="4" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="1个月" value="5" ></el-option>
                            <el-option label="3个月" value="6"></el-option>
                            <el-option label="6个月" value="7"></el-option>
                            <el-option label="12个月" value="8"></el-option>
                            <el-option label="24个月" value="9"></el-option>
                            <el-option label="36个月" value="10"></el-option>
                        </el-select>
                </el-form-item>
                <el-form-item label="选择最长租期" v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                    <el-select v-model="planinfo.rankend" placeholder="请选择" @change="CheckTable">
                            <el-option label="1天" value="1" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="3天" value="2" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="7天" value="3" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="15天" value="4" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="1个月" value="5"></el-option>
                            <el-option label="3个月" value="6"></el-option>
                            <el-option label="6个月" value="7"></el-option>
                            <el-option label="12个月" value="8"></el-option>
                            <el-option label="24个月" value="9"></el-option>
                            <el-option label="36个月" value="10"></el-option>
                        </el-select>
                </el-form-item>
            </div>
            <div v-if="stepfour">
                <el-form-item label="引用设置" style="width:100%">
                    <el-select v-model="planinfo.example" placeholder="请选择" @change="SetPlaninfo">
                        <el-option v-for="item in examples" :key="item" :label="item.Params" :value="item.ID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="套餐标题"  prop="title" required>
                        <el-input v-model="planinfo.title" style="width:215px;"></el-input>
                </el-form-item>
                <el-form-item label="库存" prop="stock" required>
                    <el-input-number style="width:215px;" v-model="planinfo.stock" controls-position="right" min="0"></el-input-number>
                </el-form-item>
                <el-form-item label="售卖价(元)" prop="rprice" v-if="planinfo.plantype == 3">
                    <el-input-number style="width:215px;" v-model="planinfo.rprice" min="0" controls-position="right"></el-input-number>
                </el-form-item>
                <el-form-item label="原价(元)" prop="price" v-if="planinfo.plantype == 3">
                    <el-input-number style="width:215px;" v-model="planinfo.price" min="0" controls-position="right"></el-input-number>
                </el-form-item>
                <el-form-item label="押金(元)" prop="deposit" v-if="planinfo.plantype != 3">
                    <el-input-number style="width:215px;" v-model="planinfo.deposit" min="0" controls-position="right"></el-input-number>
                </el-form-item>
                <el-form-item label="成本价(元)" prop="margin" v-if="planinfo.plantype != 3">
                    <el-input-number style="width:215px;" v-model="planinfo.margin" min="0" controls-position="right"></el-input-number>
                </el-form-item>
                <el-form-item label="签约价值(元)" prop="buyoutprice" v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                    <el-input-number style="width:215px;" v-model="planinfo.buyoutprice" min="0" controls-position="right" @change="SetTable"></el-input-number>
                </el-form-item>
                
                <el-form-item style="width:100%" label="是否可以买断" prop="buyout" required v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                    <el-radio-group v-model="planinfo.buyout" style="width: 215px">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否可以续租" prop="rerent" required v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                    <el-radio-group v-model="planinfo.rerent" style="width: 215px">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="续租方案" prop="rerentprice" v-if="planinfo.rerent == 1" :required ="planinfo.rerent == 1?true:false" >
                        <el-input-number style="width:215px;" v-model="planinfo.rerentprice" :min="0.8" :max="1.6" controls-position="right" :step="0.1" :precision="1"></el-input-number>
                        <el-tag type="danger" size="mini" style="margin-left:10px;">*填写0.8-1.6范围内的数字</el-tag>
                </el-form-item>
                
                <el-form-item style="width:100%" label="选择租期范围" prop="rentrank" v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                        <el-select v-model="planinfo.rankstart" placeholder="请选择" @change="SetTable">
                            <el-option label="1天" value="1" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="3天" value="2" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="7天" value="3" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="15天" value="4" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="1个月" value="5" ></el-option>
                            <el-option label="3个月" value="6"></el-option>
                            <el-option label="6个月" value="7"></el-option>
                            <el-option label="12个月" value="8"></el-option>
                            <el-option label="24个月" value="9"></el-option>
                            <el-option label="36个月" value="10"></el-option>
                        </el-select>
                        <span style="width:15px"> - </span>
                        <el-select v-model="planinfo.rankend" placeholder="请选择" @change="SetTable">
                            <el-option label="1天" value="1" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="3天" value="2" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="7天" value="3" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="15天" value="4" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                            <el-option label="1个月" value="5"></el-option>
                            <el-option label="3个月" value="6"></el-option>
                            <el-option label="6个月" value="7"></el-option>
                            <el-option label="12个月" value="8"></el-option>
                            <el-option label="24个月" value="9"></el-option>
                            <el-option label="36个月" value="10"></el-option>
                        </el-select>
                </el-form-item>
                <el-form-item :label="pricelabel" style="width: 100%;" v-if="planinfo.plantype != 3">
                    <el-table :data="planinfo.tabledata" border style="width: 100%; margin: 1em 0;" size="mini">
                        <el-table-column v-for="column in planinfo.tablecolumn" :key="column.label" :label="column.label" :prop="column.prop">
                            <template #default='scope'>
                                <el-input v-model="scope.row[column.prop].val" min="0" @change="CheckRentPrice(scope.row[column.prop].val,scope.$index,column.prop)" size="mini" :disabled="scope.row[column.prop].disabled"></el-input>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-tag type="danger" size="small">列表从上至下分别为 所选租期/租金/总租金</el-tag>
                </el-form-item>
                <!--
                <el-form-item label="保障服务" style="width:100%">
                        <el-checkbox-group v-model="planinfo.guarante" class="gurant-group" @change="ResetGroup">
                            <div v-if="planinfo.guarantinfo.length > 0">
                                <div v-for="guarante in guarantes" :key="guarante">
                                    <el-checkbox :label="guarante.ID">{{guarante.GuarantTitle}} </el-checkbox>
                                    <el-card v-if="planinfo.guarante.indexOf(guarante.ID)!=-1">
                                        <div class="gurantopt">
                                            <div class="guarantlabel">
                                                <span>价格(元)：</span>
                                                <el-input v-model="guarante.Price" size="mini" @change="SetGuarantInfo(guarante.Price)"></el-input>
                                            </div>
                                            <div   class="guarantlabel">
                                            <span>是否必选：</span>
                                            <el-radio-group v-model="guarante.MustBuy">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="2">否</el-radio>
                                            </el-radio-group>
                                            </div>
                                            <div  class="guarantlabel">
                                            <span>是否默认勾选：</span>
                                            <el-radio-group v-model="guarante.DefaultBuy">
                                                <el-radio :label="1">是</el-radio>
                                                <el-radio :label="2">否</el-radio>
                                            </el-radio-group>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                            <div v-else>
                                    <div v-for="guarante in guarantes" :key="guarante">
                                        <el-checkbox :label="guarante.ID">{{guarante.GuarantTitle}} </el-checkbox>
                                        <el-card v-if="planinfo.guarante.indexOf(guarante.ID)!=-1">
                                            <div class="gurantopt">
                                                <div class="guarantlabel">
                                                    <span>价格(元)：</span>
                                                    <el-input v-model="guarante.Price" size="mini" @change="SetGuarantInfo(guarante.Price)" ></el-input>
                                                </div>
                                                <div   class="guarantlabel">
                                                <span>是否必选：</span>
                                                <el-radio-group v-model="guarante.MustBuy">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                                <div  class="guarantlabel">
                                                <span>是否默认勾选：</span>
                                                <el-radio-group v-model="guarante.DefaultBuy">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                            </div>
                                        </el-card>
                                        
                                    </div>
                                </div>
                        </el-checkbox-group>
                    </el-form-item>-->
            </div>
        </el-form>
        <div class="bottom">
            <el-button size="small" @click="BackToStepOne" v-if="steptwo">上一步</el-button>
            <el-button size="small" @click="BackToStepTwo" v-if="stepthree">上一步</el-button>
            <!--<el-button size="small" @click="BackToStepThree" v-if="stepfour">上一步</el-button>-->
            <el-button size="small" @click="StepTwo" v-if="stepone">下一步</el-button>
            <el-button size="small" @click="BatchGenerate" v-if="stepthree && multiple">生成</el-button>
            <el-button size="small" @click="StepThree" v-if="steptwo">下一步</el-button>
            <el-button size="small" @click="StepFour" v-if="stepthree && !multiple">生成</el-button>
            <el-button size="small" type="success" @click="Sumbitform('planinfo')" v-if="stepfour">保存</el-button>
            <el-button size="small" @click="CloseDialog">取消</el-button>
        </div>

    </el-dialog>
  </div>
</template>
<style scoped>

.content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.content .sel-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.sellabel {
    font-size: 14px;
    font-weight: bold;
    margin-right: 15px;
    margin-bottom: 15px;
}
.gurant-group{
    font-size: 12px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.guarantlabel{
    width: 150px;
    margin-right:10px;
}
.gurantopt{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.bottom{
    margin-top: 50px;
    padding-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /*border-top: #dcdfe6 1px solid;*/
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-title {
    font-weight: bold;
    color: #4299e6;
}
.hideoption {
    display:none;
}

.param-edit-block{
    margin-top: 5px;
}
</style>
<script>
import { ElMessage } from 'element-plus'
import constant from "@/constant"
import Cookies from 'js-cookie'

export default {
  
    data(){
        return{
            merchants:[],
            examples:[],
            listdata:[],
            pricelabel:'租金(按天平均)',
            multiple:false,
            stepthree:false,
            steptwo:false,
            stepone:true,
            stepfour:false,
            dialogwidth:'30%',
            dialogtitle:'选择参数',
            addplan:false,
            next:false,
            props:{
                label:'label',
                value:'value',
                disabled:'Disabled'
            },
            key:[],
            params:[],
            vals:[],
            products:[],
            brands:[],
            models:[],
            categorys:[],
            rank:"",
            guarantes:[],
            planinfo:{
                id:'',
                merchantid:'',
                category:'',
                brand:'',
                model:'',
                pdt:'',
                plantype:'1',
                renttype: 1,
                rankstart:'1',
                rankend:'8',
                guarantinfo:[],
                guarante:[],
                tabledata:[],
                tablecolumn:[],
                buyout:2,
                rerent:2,
                buyoutprice:0,
                rprice:0,
                price:0,
                example:"",
                stock:"",
                title:"",
            },
            rules:{
                title:[{
                    required: true,
                    message: '请输入套餐标题',
                    trigger: 'blur'
                }],
                stock:[{
                    required: true,
                    message: '请输入库存',
                    trigger: 'blur'
                }],
            }
        }
    },
    methods:{
        Enable(id){
            console.log(id);
            this.axios.get(constant.enable_plan_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id: id
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    ElMessage.success({
                        type:'success',
                        message:'上架成功'
                    });
                    this.NextStep();
                }else{
                    this.$message.error("信息不全，无法上架");
                    return false;
                }
            })
        },
        Disable(id){
            console.log(id);
            this.axios.get(constant.disable_plan_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id: id
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    ElMessage.success({
                        type:'success',
                        message:'下架成功'
                    });
                    this.NextStep();
                }else{
                    return false;
                }
            })
        },
        SetPlaninfo(id){
            console.log(id);
            this.axios.get(constant.edit_plan_url,{
                        headers:{
                            'Content-Type': 'application/json'
                        },
                        params :{
                            id : id
                        }
            }).then((response)=>{
                console.log(response.data);
                this.addplan = true;
                this.stepfour = true;
                this.stepone = false;
                this.dialogwidth = "85%";
                this.dialogtitle = "编辑套餐信息";
                this.planinfo.stock = response.data.planinfo.Stock*1;
                this.planinfo.title = response.data.planinfo.PlanTitle;
                this.planinfo.margin = response.data.planinfo.Margin*1;
                this.planinfo.deposit = response.data.planinfo.Deposit*1;
                this.planinfo.rprice = response.data.planinfo.RPrice;
                this.planinfo.price = response.data.planinfo.Price;
                this.planinfo.buyout = response.data.planinfo.Buyout*1;
                this.planinfo.buyoutprice = response.data.planinfo.BuyoutPrice*1;
                this.planinfo.rerent = response.data.planinfo.Rerent*1;
                this.planinfo.rerentprice = response.data.planinfo.RerentPrice*1;
                this.planinfo.rankstart = response.data.planinfo.RankStart;
                this.planinfo.rankend = response.data.planinfo.RankEnd;
                this.planinfo.tablecolumn = response.data.planinfo.TableColumn;
                this.planinfo.tabledata = response.data.planinfo.Tabledata;
                if(response.data.planinfo.guarante)
                    this.planinfo.guarante = response.data.planinfo.guarante;
                if(response.data.planinfo.guarantinfo)
                    this.planinfo.guarantinfo = response.data.planinfo.guarantinfo;
                console.log("info");
                console.log(this.planinfo.guarante);
                console.log(this.planinfo.guarantinfo);
                this.guarantes = response.data.guarantes;
                this.axios.get(constant.get_guarant_url,{
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    params:{
                        status:1,
                        planid:id
                    }
                }).then((response)=>{
                    console.log("this.guarantes");
                    console.log(response.data);
                    this.guarantes = response.data;
                    if(this.planinfo.guarantinfo){
                        for(let i = 0;i<this.guarantes.length;i++){
                            let cur_id = this.guarantes[i]['ID'];
                            for(let j = 0;j<this.planinfo.guarantinfo.length;j++){
                                if(cur_id == this.planinfo.guarantinfo[j]['GuarantID']){
                                    this.guarantes[i].Price = this.planinfo.guarantinfo[j]['Price'];
                                    this.guarantes[i].MustBuy = this.planinfo.guarantinfo[j]['MustBuy'];
                                    this.guarantes[i].DefaultBuy = this.planinfo.guarantinfo[j]['DefaultBuy'];
                                }
                            }
                            
                        }
                    }
                    
                })
            }) 
        },
        ResetGroup(){
            //console.log('reset');
            //console.log(this.planinfo.guarante);
            //console.log(this.planinfo.guarantinfo);
            var newinfo = [];
            for(let i = 0;i<this.planinfo.guarante.length;i++){
                for(let j = 0;j<this.guarantes.length;j++){
                    if(this.guarantes[j].ID == this.planinfo.guarante[i]){
                        newinfo.push(this.guarantes[j]);
                    }
                }
            }
            console.log(newinfo);
            this.planinfo.guarantinfo = newinfo;
        },
        ResetStart(){
            if(this.planinfo.plantype == '2'){
                if(this.planinfo.rankstart < 5)
                    this.planinfo.rankstart = "5";
                this.planinfo.renttype = 3;
            }else if(this.planinfo.plantype == '1' || this.planinfo.plantype == '4'){
                this.planinfo.renttype = 1;
            }
        },
        GetRow(vals){
            var outdata = Array();
            for(let i =0;i<vals.length;i++){
                let key = vals[i];
                let newrows = Array();
                if(outdata.length <= 0){
                    for(let k=0;k<this.planinfo[key].length;++k)
                    {
                        let newrow = new Object();
                        newrow[key] = this.planinfo[key][k];
                        newrows.push(newrow);
                    }
                }else{
                    for(let i=0;i<outdata.length;++i){
                        for(let k=0;k<this.planinfo[key].length;++k)
                        {
                            let newrow = new Object();
                            for(let key1 in outdata[i])
                                newrow[key1] = outdata[i][key1];
                            newrow[key] = this.planinfo[key][k];
                            newrows.push(newrow);
                        }
                    }
                }
                outdata = newrows;
            }
            return outdata;
        },
        BatchGenerate(){
            this.SetTable();
            this.planinfo.rows = this.GetRow(this.planinfo.params);
            console.log(this.planinfo);
            this.axios.post(constant.batch_add_plan_url,this.planinfo,{
                headers:{'Content-Type': 'application/x-www-form-urlencoded'}
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.multiple = false;
                    this.addplan = false;
                    this.stepone = true;
                    this.dialogwidth = "30%";
                    this.dialogtitle = "选择参数";
                    this.steptwo = false;
                    this.stepthree = false;
                    this.planinfo.params = "";
                    this.NextStep();
                }else{
                    this.$message.error("操作失败");
                }
            });
        },
        BatchAdd(){
            this.multiple = true;
            this.addplan = true;
        },
        DelPlan(id){
            this.$confirm('确定删除该套餐么？','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                this.axios.get(constant.del_plan_url,{
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    params:{
                        id:id
                    }
                }).then((response)=>{
                    console.log(response);
                    if(response.data.msg == "OK"){
                        this.$message.success("操作成功");
                        this.NextStep();
                    }else{
                        ElMessage.error({
                            type:'error',
                            message: '操作失败'
                        });
                    }
                });
            }).catch(()=>{
                return false;
            });
            
            return false;
        },
        BatchSave(){
            let flag = true;
            this.listdata.forEach(row => {
                if(!row.PlanTitle || !row.Stock){
                   flag = false;
                }
            })
            if(flag == false){
                this.$message.error("请完善所有套餐的标题和库存信息");
                return false;
            }else{
                this.axios.post(constant.batchsave_plan_url,this.listdata,{
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    
                }).then((response)=>{
                    console.log(response.data);
                    if(response.data == "OK"){
                        this.$message.success("保存成功");
                        this.NextStep();
                        //this.$router.push({path:'/planlist'});
                    }
                });
            }
            
        },
        Back(){
            this.$router.push({path:'/planlist'});
        },
        GetPlan(){
            this.multiple = false;
            this.stepone = true;
            this.dialogwidth = "30%";
            this.dialogtitle = "选择参数";
            this.steptwo = false;
            this.stepthree = false;
            this.stepfour = false;
            this.planinfo.params = "";
            this.NextStep();
        },
        ResetPdt(){
            this.next = false;
        },
        SetGurantGroup(){
            this.planinfo.guarantinfo = []
            for(let i = 0;i<this.planinfo.guarante.length;i++){
                for(let j = 0;j<this.guarantes.length;j++){
                    if(this.guarantes[j].ID == this.planinfo.guarante[i]){
                        this.planinfo.guarantinfo.push(this.guarantes[j]);
                    }
                }
            }
            console.log(this.planinfo.guarantinfo);
        },
        SetGuarantInfo(val){
            if(!typeof val === 'number' || isNaN(val)){
                    ElMessage.error({
                        type:'error',
                        message:'保障价格必须是数字'
                    });
                    return false;
                }
        },
        Sumbitform(formName){
            this.$refs[formName].validate((valid)=>{
                if(valid){
                    this.ResetGroup();
                    this.planinfo.key = this.key;
                    console.log(this.planinfo);
                    
                    
                    this.axios.post(constant.save_plan_url,this.planinfo,{
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == 'OK'){
                            this.planinfo.example = "";
                            ElMessage.success({
                                type:'success',
                                message:'保存成功'
                            });
                            this.NextStep();
                            this.stepone = true;
                            this.steptwo = false;
                            this.stepthree = false;
                            this.stepfour = false;
                            this.multiple = false;
                            this.addplan = false;
                            return false;
                        }else if(response.data == 'REPEATTITLE'){
                            this.$message.error("该产品的套餐标题不允许重复");
                            return false;
                        }else if(response.data == 'LOCK'){
                            this.$message.error("该产品的套餐正参与活动被锁定中，请联系运维管理员解锁");
                            return false;
                        }else{
                            ElMessage.error({
                                type:'error',
                                message:'保存失败'
                            });
                            return false;
                        }
                    });
                }else{
                    return false;
                }
            })
        },
        CheckRentPrice(val,index,prop){
            console.log(val);
            console.log(index);
            console.log(prop);
            if(val){
                if(isNaN(val)){
                    this.$message.error("金额必须是数字");
                    return false;
                }
            }
            var total_row = this.planinfo.tabledata[1];
            var cur_row = this.planinfo.tabledata[0];
            
            if(prop == 'd1'){
                total_row[prop].val = (val*1).toFixed(2);
            }
            if(prop == 'd3'){
                if(this.planinfo.rankstart != 2 && val*1 > cur_row.d1.val*1)
                    this.$message.error("后一阶的租金不能高于前一阶")
                total_row[prop].val = (val*3).toFixed(2);
            }
            if(prop == 'd7'){
                if(this.planinfo.rankstart != 3 && (val*1 > cur_row.d3.val*1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                total_row[prop].val = (val*7).toFixed(2);
            }
            if(prop == 'd15'){
                if(this.planinfo.rankstart != 4 && (val*1 > cur_row.d7.val*1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                total_row[prop].val = (val*15).toFixed(2);
            }
            if(prop == 'm1'){
                if(this.planinfo.rankstart != 5 && (val*1 > cur_row.d15.val*1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if(this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val*30).toFixed(2);
                else
                    total_row[prop].val = (val*1).toFixed(2);
                
            }
            if(prop == 'm3'){
                if(this.planinfo.rankstart != 6 && (val*1 > cur_row.m1.val*1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if(this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val*90).toFixed(2);
                else
                    total_row[prop].val = (val*3).toFixed(2);
                
            }
            if(prop == 'm6'){
                if(this.planinfo.rankstart != 7 && (val*1 > cur_row.m3.val*1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if(this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val*180).toFixed(2);
                else
                    total_row[prop].val = (val*6).toFixed(2);
                
            }
            if(prop == 'y1'){
                if(this.planinfo.rankstart != 8 && (val*1 > cur_row.m6.val*1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if(this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val*365).toFixed(2);
                else
                   total_row[prop].val = (val*12).toFixed(2); 
                
            }
            if(prop == 'y2'){
                if(this.planinfo.rankstart != 9 && (val*1 > cur_row.y1.val*1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if(this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val*730).toFixed(2);
                else
                    total_row[prop].val = (val*24).toFixed(2); 
                
            }
            if(prop == 'y3'){
                if(this.planinfo.rankstart != 10 && (val*1 > cur_row.y2.val*1))
                    this.$message.error("后一阶的租金不能高于前一阶")
                if(this.planinfo.renttype == 1 || this.planinfo.renttype == 2)
                    total_row[prop].val = (val*1095).toFixed(2);
                else
                    total_row[prop].val = (val*36).toFixed(2); 
            }
        },
        ResetType(){
            if(this.planinfo.renttype == 3 || this.planinfo.renttype == 4){
                if(this.planinfo.renttype == 3)
                    this.pricelabel = "租金(按月平均)";
                if(this.planinfo.renttype == 4)
                    this.pricelabel = "租金(按月阶梯)";
                if(this.planinfo.rankstart*1 < 5)
                    this.planinfo.rankstart = '5';
            }else{
                if(this.planinfo.renttype == 1)
                    this.pricelabel = "租金(按天平均)";
                if(this.planinfo.renttype == 2)
                    this.pricelabel = "租金(按天阶梯)";
            }
            
            this.planinfo.tablecolumn = [];
            this.planinfo.tabledata = [];
            
        },
        SetParam(val){
            //设置参数
            console.log(val);
            this.vals = val;
        },
        StepTwo(){
            console.log("设置参数");
            this.axios.get(constant.get_sel_param_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    selparam: JSON.stringify(this.vals)
                }
            }).then((response)=>{
                console.log("参数列表");
                console.log(response.data);
                
                this.key = [];
                this.params = response.data;//参数值具体信息
                for(var i =0;i<this.params.length;i++){
                    var name = this.params[i]['param_id'];
                    this.key.push(name);
                    this.planinfo[name] = [];
                }
                console.log(this.key);
                console.log(this.planinfo);
                this.stepone = false;
                this.steptwo = true;
                this.dialogtitle = "设置参数";
            });
        },
        StepThree(){
            
            console.log("选择租赁租期");

            /*
            this.axios.get(constant.check_plan_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    str: str,
                    pdt: this.planinfo.pdt,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.stepthree = true;
                    this.steptwo = false;
                    this.stepone = false;
                    this.dialogtitle = "选择租赁租期";
                    this.dialogwidth = "40%";
                }else{
                    this.$message.error("该参数已经创建过套餐");
                    return false;
                }
            });*/
            this.stepthree = true;
            this.steptwo = false;
            this.stepone = false;
            this.dialogtitle = "选择租赁租期";
            this.dialogwidth = "40%";
            
        },
        StepFour(){
            let str = "";
            for(let i = 0;i<this.key.length;i++){
                let prop = this.key[i];
                let val = this.planinfo[prop];
                str = str + val + ",";
            }
            str = str.slice(0,str.length-1)
            console.log("str--" + str);
            this.axios.get(constant.check_plan_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    str: str,
                    pdt: this.planinfo.pdt,
                    plantype: this.planinfo.plantype,
                    merchantid: this.planinfo.merchantid,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.SetTable();
                    console.log(this.planinfo);
                    this.axios.post(constant.temp_save_plan_url,this.planinfo,{
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'}
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == "OK"){
                            this.multiple = false;
                            this.addplan = false;
                            this.stepone = true;
                            this.dialogwidth = "30%";
                            this.dialogtitle = "选择参数";
                            this.steptwo = false;
                            this.stepthree = false;
                            this.stepfour = false;
                            this.planinfo.params = "";
                            this.NextStep();
                        }else{
                            this.$message.error("操作失败");
                        }
                    });
                }else{
                    this.$message.error("该参数已经创建过套餐");
                    return false;
                }
            });
            
        },
        BackToStepOne(){
            console.log("选择参数");
            this.key = [];
            this.params = [];
            this.steptwo = false;
            this.stepone = true;
            this.dialogtitle = "选择参数";
        },
        BackToStepTwo(){
            console.log("设置参数");
            this.steptwo = true;
            this.stepthree = false;
            this.dialogtitle = "设置参数";
            this.dialogwidth = "30%";
        },
        BackToStepThree(){
            this.stepfour = false;
            this.stepthree = true;
            this.dialogtitle = "选择租赁租期";
            this.dialogwidth = "40%";
        },
        CloseDialog(){
            this.multiple = false;
            this.addplan = false;
            this.planinfo.params = "";
            this.dialogwidth = "30%";
            this.dialogtitle = "选择参数";
            this.stepone = true;
            this.steptwo = false;
            this.stepthree = false;
            this.stepfour = false;
        },
        ChangeMerchant(){
            this.planinfo.category = "";
            this.planinfo.brand = "";
            this.planinfo.model = "";
            this.planinfo.pdt = "";
            this.next = false;
        },
        HandleCateChange(){
            this.planinfo.brand = "";
            this.planinfo.model = "";
            this.planinfo.pdt = "";
            this.next = false;
        },
        GetPdt(){
            
            this.axios.get(constant.get_plan_pdt_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    category: JSON.stringify(this.planinfo.category),
                    brand: this.planinfo.brand,
                    model: this.planinfo.model,
                    status: 1,
                    merchantid:this.planinfo.merchantid,
                }
            }).then((response)=>{
                console.log("get");
                console.log(response.data);
                this.planinfo.pdt = '';
                this.next = false;
                this.products = response.data;
                
            });
        },
        RefleshPdt(){
            console.log('merchantid--' + this.planinfo.merchantid);
            this.axios.get(constant.get_plan_pdt_url,{
                
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    category: JSON.stringify(this.planinfo.category),
                    brand: this.planinfo.brand,
                    model: this.planinfo.model,
                    status: 1,
                    merchantid:this.planinfo.merchantid,
                }
            }).then((response)=>{
                console.log("reflesh");
                console.log(response.data);
                
                this.products = response.data;
            });
        },
        GetModel(){
            console.log("getmodel");
            this.axios.get(constant.get_plan_model_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    category: JSON.stringify(this.planinfo.category),
                    brand: this.planinfo.brand,
                    status: 1,
                }
            }).then((response)=>{
                console.log(response.data);
                this.planinfo.model = '';
                this.next = false;
                this.models = response.data;
            });
        },
        ClearBrand(){
            this.planinfo.model = "";
            this.planinfo.pdt = "";
            this.next = false;
        },
        ClearModel(){
            this.planinfo.pdt = "";
            this.next = false;
        },
        NextStep(){
            if(this.planinfo.category && this.planinfo.brand && this.planinfo.model && this.planinfo.pdt){
                //查询所有该产品的相关套餐,形成列表,如果没有，则为空，准备下一步添加
                this.axios.get(constant.get_pdt_plan_url,{
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    params:{
                        id: this.planinfo.pdt
                    }
                }).then((response)=>{
                    console.log(response);
                    this.listdata = response.data;
                    this.GetParam();
                    this.next = true;
                });
            }
        },
        GetParam(){
            this.axios.get(constant.get_plan_param_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    category: JSON.stringify(this.planinfo.category),
                    status: 1,
                }
            }).then((response)=>{
                //console.log("参数---");
                console.log(response.data);
                this.selparam = response.data;

            })
        },
        AddPlan(){
            //单独添加套餐
            this.addplan = true;
        },
        SetTable(){
            if(this.planinfo.plantype != 3){
                var tablecolumn = Array();
                for(var key in this.rank){
                    var cur_rank_obj = this.rank[key];
                    var rank_obj = Object();
                    if(this.planinfo.renttype == 3 || this.planinfo.renttype == 4)
                        rank_obj.label = cur_rank_obj.label + "(元/月)";
                    else
                        rank_obj.label = cur_rank_obj.label + "(元/天)";
                    rank_obj.prop = cur_rank_obj.prop
                    rank_obj.val = cur_rank_obj.val;
                    tablecolumn.push(rank_obj);
                }
                console.log(tablecolumn);
                this.planinfo.tablecolumn = tablecolumn;

                var tabledata = Array();

                var row_obj = Object();//填写价格的行

                var total_row_obj = Object();//计算总租金的行

                if(this.planinfo.plantype != 3){
                    for(let key in this.rank){
                        let cur_rank_obj = this.rank[key];
                        let rank_prop = cur_rank_obj.prop;
                        let cur_obj = Object();
                        let total_cur_obj = Object();
                        if(key*1 >= (this.planinfo.rankstart)*1 && (this.planinfo.rankend)*1 >= key*1){
                            
                            if(this.planinfo.plantype != 2){
                                cur_obj.val = '';
                                total_cur_obj.val = '';
                            }
                            else{
                                if(key*1 == 5){
                                    cur_obj.val = (this.planinfo.buyoutprice/1).toFixed(2);
                                    total_cur_obj.val = (cur_obj.val*1).toFixed(2);
                                }
                                if(key*1 == 6){
                                    cur_obj.val = (this.planinfo.buyoutprice/3).toFixed(2);
                                    total_cur_obj.val = (cur_obj.val*3).toFixed(2);
                                }
                                if(key*1 == 7){
                                    cur_obj.val = (this.planinfo.buyoutprice/6).toFixed(2);
                                    total_cur_obj.val = (cur_obj.val*6).toFixed(2);
                                }
                                if(key*1 == 8){
                                    cur_obj.val = (this.planinfo.buyoutprice/12).toFixed(2);
                                    total_cur_obj.val = (cur_obj.val*12).toFixed(2);
                                }
                                if(key*1 == 9){
                                    cur_obj.val = (this.planinfo.buyoutprice/24).toFixed(2);
                                    total_cur_obj.val = (cur_obj.val*24).toFixed(2);
                                }
                                if(key*1 == 10){
                                    cur_obj.val = (this.planinfo.buyoutprice/36).toFixed(2);
                                    total_cur_obj.val = (cur_obj.val*36).toFixed(2);
                                }
                            }
                            cur_obj.disabled = false;
                            
                            total_cur_obj.disabled = true;
                            
                        }else{
                            cur_obj.val = '';
                            cur_obj.disabled = true;
                            total_cur_obj.val = '';
                            total_cur_obj.disabled = true;
                        }
                        row_obj[rank_prop] = cur_obj;
                        total_row_obj[rank_prop] = total_cur_obj;
                    }
                }
                tabledata.push(row_obj);
                tabledata.push(total_row_obj);
                this.planinfo.tabledata = [];
                this.planinfo.tabledata = tabledata;
                console.log(this.planinfo.tabledata);
            }
        },
        EditPlan(id){
            
            this.axios.get(constant.edit_plan_url,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params :{
                    id : id
                }
            }).then((response)=>{
                console.log(response.data);
                this.addplan = true;
                this.stepfour = true;
                this.stepone = false;
                this.dialogwidth = "70%";
                this.dialogtitle = "编辑套餐信息";
                this.planinfo.plantype = response.data.planinfo.Plantype;
                
                this.planinfo.renttype = response.data.planinfo.RentType;
                if(this.planinfo.renttype == 3 || this.planinfo.renttype == 4){
                    if(this.planinfo.renttype == 3)
                        this.pricelabel = "租金(按月平均)";
                    if(this.planinfo.renttype == 4)
                        this.pricelabel = "租金(按月阶梯)";
                }else{
                    if(this.planinfo.renttype == 1)
                        this.pricelabel = "租金(按天平均)";
                    if(this.planinfo.renttype == 2)
                        this.pricelabel = "租金(按天阶梯)";
                }
                    
                this.planinfo.stock = response.data.planinfo.Stock*1;
                this.planinfo.title = response.data.planinfo.PlanTitle;
                this.planinfo.id = response.data.planinfo.ID;
                this.planinfo.margin = response.data.planinfo.Margin*1;
                this.planinfo.deposit = response.data.planinfo.Deposit*1;
                this.planinfo.rprice = response.data.planinfo.RPrice;
                this.planinfo.price = response.data.planinfo.Price;
                this.planinfo.buyout = response.data.planinfo.Buyout*1;
                this.planinfo.buyoutprice = response.data.planinfo.BuyoutPrice*1;
                this.planinfo.rerent = response.data.planinfo.Rerent*1;
                this.planinfo.rerentprice = response.data.planinfo.RerentPrice*1;
                this.planinfo.rankstart = response.data.planinfo.RankStart;
                this.planinfo.rankend = response.data.planinfo.RankEnd;
                this.planinfo.tablecolumn = response.data.planinfo.TableColumn;
                this.planinfo.tabledata = response.data.planinfo.Tabledata;
                this.planinfo.guarante = [];
                if(response.data.planinfo.guarante)
                    this.planinfo.guarante = response.data.planinfo.guarante;
                this.planinfo.guarantinfo = [];
                if(response.data.planinfo.guarantinfo)
                    this.planinfo.guarantinfo = response.data.planinfo.guarantinfo;
                console.log("info");
                console.log(this.planinfo.guarante);
                console.log(this.planinfo.guarantinfo);
                this.guarantes = response.data.guarantes;
                this.axios.get(constant.get_guarant_url,{
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    params:{
                        status:1,
                        ismerchant:this.ismerchant,
                        mid:this.mid,
                        planid:id,
                    }
                }).then((response)=>{
                    console.log("this.guarantes");
                    console.log(response.data);
                    this.guarantes = response.data;
                    if(this.planinfo.guarantinfo){
                        console.log("有选择保障服务");
                        for(let i = 0;i<this.guarantes.length;i++){
                            let cur_id = this.guarantes[i]['ID'];
                            for(let j = 0;j<this.planinfo.guarantinfo.length;j++){
                                if(cur_id == this.planinfo.guarantinfo[j]['GuarantID']){
                                    this.guarantes[i].Price = this.planinfo.guarantinfo[j]['Price'];
                                    this.guarantes[i].MustBuy = this.planinfo.guarantinfo[j]['MustBuy'];
                                    this.guarantes[i].DefaultBuy = this.planinfo.guarantinfo[j]['DefaultBuy'];
                                }
                            }
                            
                        }
                    }
                    
                })
                this.axios.get(constant.get_example_plan_url,{
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    params :{
                        plantype : this.planinfo.plantype,
                        pdt: this.planinfo.pdt,
                        id: id,
                        merchantid: this.planinfo.merchantid,
                    }
                }).then((response)=>{
                    console.log("example");
                    console.log(response.data);
                    this.examples = response.data;
                })
            }) 
        },
    },
    
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        if(Cookies.get("mid") != 'undefined')
            this.planinfo.merchantid = Cookies.get("mid");
        this.axios.get(constant.get_brand_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params :{
                status : 1
            }
        }).then((response)=>{
            console.log(response.data);
            this.brands = response.data;
        });
        
        this.axios.get(constant.get_cascader_url,{
            headers:{
                'Content-Type': 'application/json'
            },
        }).then((response)=>{
            console.log(response.data);
            this.categorys = response.data;
        });
        this.axios.get(constant.get_rank_url,{
            headers:{
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            console.log(response.data);
            this.rank = response.data;
            //this.SetTable();
        });

        this.axios.get(constant.get_guarant_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params:{
                status:1,
                ismerchant:this.ismerchant,
                mid:this.mid,
            }
        }).then((response)=>{
            console.log("保障");
            console.log(response.data);
            this.guarantes = response.data;
            this.planinfo.guarantinfo = [];
            for(let i = 0;i<this.guarantes.length;i++){
                let arr = Object();
                arr.price = 0;
                arr.mustbuy = '';
                arr.defaultbuy = '';
            }
        })

        this.axios.get(constant.allmerchant,{
            headers:{
                'Content-Type': 'application/json'
            },
        }).then((response)=>{
            console.log(response.data);
            this.merchants = response.data;
        });

        let pdtid = this.$route.query.pdtid;
        if(pdtid){
            this.axios.get(constant.getpdtparams,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    id:pdtid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.planinfo.merchantid = response.data.MerchantID;
                this.planinfo.category = response.data.CategoryID;
                this.planinfo.brand = response.data.BrandID;
                let modelid = response.data.ModelID;
                let pdtid = response.data.ID;
                this.axios.get(constant.get_plan_model_url,{
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    params:{
                        category: JSON.stringify(this.planinfo.category),
                        brand: this.planinfo.brand,
                        status: 1,
                    }
                }).then((response)=>{
                    console.log(response.data);
                    this.models = response.data;
                    this.planinfo.model = modelid;
                    
                    this.axios.get(constant.get_plan_pdt_url,{
                        headers:{
                            'Content-Type': 'application/json'
                        },
                        params:{
                            category: JSON.stringify(this.planinfo.category),
                            brand: this.planinfo.brand,
                            model: this.planinfo.model,
                            status: 1,
                            merchantid:this.planinfo.merchantid,
                        }
                    }).then((response)=>{
                        this.next = false;
                        this.products = response.data;
                        this.planinfo.pdt = pdtid;
                        this.NextStep();
                    });
                });
                
            });
        }
    }
    
};
</script>